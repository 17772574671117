import gql from 'graphql-tag'

export const PRODUCTS_SHOWCASE_LIST_QUERY = gql`
  query ProductShowcaseQuery(
    $path: PagePath!
    $id: ID!
    $input: ProductListInput!
    $currency: Currency!
    $shippingDestination: Country!
  ) {
    productsShowcaseList(
      path: $path
      id: $id
      input: $input
      currency: $currency
      shippingDestination: $shippingDestination
    ) @client {
      __typename
      products {
        __typename
        sku
        url
        title
        defaultVariant(
          options: {
            currency: $currency
            shippingDestination: $shippingDestination
          }
        ) {
          price(
            currency: $currency
            shippingDestination: $shippingDestination
          ) {
            price {
              amount
              displayValue
            }
            rrp {
              amount
              displayValue
            }
          }
        }
        cheapestVariant(
          currency: $currency
          shippingDestination: $shippingDestination
        ) {
          price(
            currency: $currency
            shippingDestination: $shippingDestination
          ) {
            price {
              amount
              displayValue
            }
            rrp {
              amount
              displayValue
            }
          }
        }
        mostExpensiveVariant(
          currency: $currency
          shippingDestination: $shippingDestination
        ) {
          price(
            currency: $currency
            shippingDestination: $shippingDestination
          ) {
            price {
              amount
              displayValue
            }
            rrp {
              amount
              displayValue
            }
          }
        }
        reviews {
          __typename
          total
          averageScore
        }
        images(limit: 1) {
          __typename
          largeProduct
        }
        marketedSpecialOffer {
          title {
            content {
              type
              content
            }
          }
          description {
            content {
              type
              content
            }
          }
          landingPageLink {
            text
            url
            openExternally
            noFollow
          }
          freeGiftProduct {
            sku
            images(limit: 1) {
              largeProduct
            }
            title
            price(
              currency: $currency
              shippingDestination: $shippingDestination
            ) {
              rrp {
                displayValue
              }
            }
          }
        }
        inStock
      }
    }
  }
`
